<template>
  <div class="p-fluid">
    <form @submit.prevent="onActivate">
      <p>{{ $t("activation.text1") }}</p>
      <div class="p-field">
        <label for="new-password">{{ $t("activation.field_password") }}</label>
        <Password
          id="new-password"
          v-model="password"
          aria-label="new-password"
          aria-describedby="new-password-error"
          toggle-mask
          :placeholder="translation.passwordPlaceholder"
          :feedback="true"
          :class="{ 'p-invalid': errors['password'] }"
          :disabled="isRequesting"
        />
        <small
          v-if="errors['password']"
          id="new-password-error"
          class="p-error"
          role="alert"
          aria-live="polite"
          >{{ $t("activation.field_password_error") }}</small
        >
      </div>
      <div class="p-field">
        <label for="verify-password">{{
          $t("activation.field_password_verify")
        }}</label>
        <Password
          id="verify-password"
          v-model="verifyPassword"
          aria-label="verify-password"
          aria-describedby="verify-password-error"
          toggle-mask
          :placeholder="translation.passwordVerifyPlaceholder"
          :feedback="false"
          :class="{ 'p-invalid': errors['verifyPassword'] }"
          :disabled="isRequesting"
        />
        <small
          v-if="errors['verifyPassword']"
          id="verify-password-error"
          class="p-error"
          role="alert"
          aria-live="polite"
          >{{ $t("activation.field_password_mismatch") }}</small
        >
      </div>
      <div class="p-field">
        <div>
          <Checkbox
            v-model="legal"
            aria-label="legal-label"
            :binary="true"
            aria-describedby="legal-error"
          />
          <span id="legal-label" class="legal p-ml-2" @click="toggleLegal">
            {{ $t("activation.legal_text") }}&nbsp;</span
          >
          <a :href="translation.legalUrl" target="_blank">{{
            $t("activation.legal_link_text")
          }}</a
          >.<br />
        </div>
        <small
          v-if="errors['legalAgreement']"
          id="legal-error"
          class="p-error"
          role="alert"
          >{{ $t("activation.legal_error") }}</small
        >
      </div>
      <Button
        class="p-mt-4 p-button-lg"
        :label="translation.submitButton"
        type="submit"
        :disabled="isRequesting"
      />
    </form>
    <Divider />
    <p>
      {{ $t("activation.text2") }}&nbsp;<strong>{{ username }}</strong>
    </p>
  </div>
</template>

<script>
import { computed, ref } from "@vue/reactivity";
import Button from "primevue/button";
import Checkbox from "primevue/checkbox";
import Divider from "primevue/divider";
import Password from "primevue/password";
import { useToast } from "primevue/usetoast";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

import { TOAST_LIFE } from "../config";
import { assertPassword } from "../service/assert";
import { USER_ACTIVATION } from "../store/modules/auth/auth.actions.type";
import { GET_LOADING_STATUS } from "../store/modules/global/global.getters.type";

export default {
  name: "UserActivation",
  components: {
    Button,
    Checkbox,
    Divider,
    Password,
  },
  setup() {
    const store = useStore();
    const toast = useToast();
    const route = useRoute();
    const router = useRouter();
    const { t } = useI18n();
    const { username, token } = route.params;

    const password = ref("");
    const verifyPassword = ref("");
    const legal = ref(false);
    const errors = ref([]);
    const isRequesting = computed(() => store.getters[GET_LOADING_STATUS]);
    const translation = {
      legalUrl: t("activation.legal_document"),
      passwordPlaceholder: t("activation.field_password_placeholder"),
      passwordVerifyPlaceholder: t(
        "activation.field_password_verify_placeholder"
      ),
      submitButton: t("activation.submit_button"),
    };

    async function onActivate() {
      if (validateInputs()) {
        await store.dispatch(USER_ACTIVATION, {
          token: token,
          username: username,
          password: password.value,
          legal: legal.value,
        });
        await router.push({ name: "login" });
        toast.add({
          severity: "success",
          summary: t("common.success"),
          detail: t("activation.toast_success"),
          life: TOAST_LIFE,
        });
      }
    }

    function validateInputs() {
      errors.value = [];
      if (!assertPassword(password.value)) {
        errors.value["password"] = true;
      }
      if (password.value !== verifyPassword.value) {
        errors.value["verifyPassword"] = true;
      }
      if (!legal.value) {
        errors.value["legalAgreement"] = true;
      }
      return Object.keys(errors.value).length === 0;
    }

    const toggleLegal = () => (legal.value = !legal.value);

    return {
      username,
      password,
      verifyPassword,
      errors,
      toggleLegal,
      legal,
      isRequesting,
      onActivate,
      translation,
    };
  },
};
</script>

<style scoped>
.legal {
  cursor: pointer;
}
</style>
