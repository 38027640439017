<template>
  <the-layout :title="title">
    <form-activation class="p-mt-6" />
  </the-layout>
</template>

<script>
import { useI18n } from "vue-i18n";

import FormActivation from "../components/FormActivation";
import TheLayout from "../components/TheLayout";

export default {
  name: "Activation",
  components: {
    FormActivation,
    TheLayout,
  },
  setup() {
    const { t } = useI18n();
    return {
      title: t("activation.title"),
    };
  },
};
</script>
